const React = require('react');
const { arrayOf, string, bool, shape, oneOf } = require('prop-types');

const { Discovery } = require('@ads-placements-frontend/discovery');
const withTracker = require('../../../commons/with-tracker');
const Section = require('../../../commons/section');
const Advertising = require('./advertising');

const DESKTOP = 'desktop';

const DiscoveryWrapper = ({ title, type, elements, id, lazyLoad, deviceType }) => ( // TODO remover ID
  <Section type={type}>
    <div className="container row">
      {// TODO Eliminar este bloque de codigo cuando suba el back
      // de ADServer, ya que no exite mas el titulo
      deviceType === DESKTOP && title && (
        <div className="section-header andes-visually-hidden">
          <h2>{title}</h2>
        </div>
      )
      }
      {// TODO Eliminar este bloque de codigo cuando suba el back
      // de ADServer y dejar solo el componente requerido del monorepo Discovery
      // (Dejar el else como unica variacion)
      !id.includes('ads') ? (
        <div className="discovery__body old_discovery">
          {elements.map((element) => (
            <Advertising
              key={element.title}
              {...element}
              lazyLoad={lazyLoad}
              deviceType={deviceType}
            />
          ))}
        </div>
      ) : (
        <Discovery
          type={type}
          items={elements}
          lazyLoad={lazyLoad}
          deviceType={deviceType}
        />
      )
      }
    </div>
  </Section>
);

DiscoveryWrapper.propTypes = {
  elements: arrayOf(
    shape({
      title: string,
    }),
  ).isRequired,
  id: string.isRequired, // TODO Remove id prop con el back en prod
  lazyLoad: bool.isRequired,
  type: string.isRequired,
  title: string,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
};

module.exports = withTracker(DiscoveryWrapper);
